<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="$t('cancel.pending')">
    <template #footer>
      <el-button data-name="cancel-restore-button"
        @click="setVisible(false)">
        {{ $t('cancel.do-not-restore') }}
      </el-button>
      <el-button data-name="restore-services-button"
        type="primary"
        @click="uncancelService">
        {{ $t('cancel.restore') }}
      </el-button>
    </template>

    <div v-loading="restoring"
      :element-loading-text="$t('cancel.restoring')">
      <p>{{ $t('cancel.cancel-termination-confirmation') }}</p>

      <div class="cancel-service-item">
        <mu-mega-icon :icon="cancelledService.productType"
          class="service-type-icon" />
        <div>
          <div class="color-text-primary">
            {{ cancelledService.productName }}
          </div>
          <div class="fs-1-4rem">
            {{ $t('ports.terminate-date', {date: terminateDate}) }}
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mpDate } from '@/helpers.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import sdk from '@megaport/api-sdk'
import { CLOUD_ITEMS } from '@/Globals.js'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    serviceUid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      restoring: false,
    }
  },

  computed: {
    ...mapGetters('Services', ['allServicesUidDictionary']),
    cancelledService() {
      return this.allServicesUidDictionary[this.serviceUid] || {}
    },
    terminateDate() {
      return mpDate(this.cancelledService.terminateDate)
    },
    /**
     * Check if any IBM connections are to be uncancelled to display modal after
     * uncancellation reminding users to confirm connection in IBM portal
     */
    serviceContainsIbm() {
      // Check if we're cancelling an IBM VXC
      // NOTE: The API returns csp_connection as an object when it contains a single connection
      // and as an array when it contains multiple, so we need to check its structure.
      const cspConnection = this.cancelledService.resources?.csp_connection

      const cancelIbmConnection = Array.isArray(cspConnection) ?
        cspConnection.some(({ csp_name }) => csp_name === 'IBM') :
        cspConnection?.csp_name === 'IBM'

      if (cancelIbmConnection) return true

      // Check if we're cancelling a service containing any IBM VXCs
      const ibmCloudItem = CLOUD_ITEMS.find(item => item.title === 'IBM Cloud')

      const cancelIbmService = this.cancelledService.associatedVxcs ?
        this.cancelledService.associatedVxcs.some(({ bEnd: { ownerUid } = {} }) => ibmCloudItem.companyUids.includes(ownerUid)) :
        false

      return cancelIbmService
    },
  },

  methods: {
    ...mapActions('Services', ['getMyServices']),
    ...mapActions('Notifications', ['showIbmReminderModal']),
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    uncancelService() {
      this.restoring = true
      sdk.instance
        .product(this.serviceUid)
        .uncancel()
        .then(data => {
          this.notifyGood({
            title: this.$t('cancel.restored'),
            message: data.message,
          })

          // Show IBM modal if an IBM connection or a service containing an IBM connection is cancelled
          if (this.serviceContainsIbm) {
            this.showIbmReminderModal()
          }

          this.getMyServices()
          this.restoring = false
          this.setVisible(false)
        })
        .catch(e => {
          if (e.data && e.data.message) {
            this.notifyBad({
              title: this.$t('cancel.error-uncancelling'),
              message: e.data.message,
            })
          } else {
            this.notifyBad({
              title: this.$t('cancel.error-uncancelling'),
              message: this.$t('general.unknown-error'),
            })
            captureSentryError(e)
          }
          this.restoring = false
        })
    },
  },
}
</script>
