<template>
  <simple-confirmation-dialog :visible="showTransitModal"
    width="50%"
    left-footer-button-string="general.not-now"
    right-footer-button-string="connections.create-ip-transit-vxc"
    @cancel="handleTransitModalClose('cancel')"
    @confirm="handleTransitModalClose('confirm')">
    <template #title>
      <div class="simple-confirmation-dialog-title-container">
        <h1>
          {{ $t('general.success') }}
        </h1>
        <span class="fas fa-check-circle color-success fs-1-4rem" />
      </div>
    </template>
    <template #default>
      <div class="simple-confirmation-dialog-body">
        <p>{{ $t('ports.mve-ip-transit-vxc-prompt-success', { vendorName: newMveReadyForTransitVxcVendor }) }}</p>
        <p>{{ $t('ports.mve-ip-transit-vxc-prompt-recommend', { vendorName: newMveReadyForTransitVxcVendor }) }}</p>
        <i18n path="ports.mve-ip-transit-vxc-prompt-docs"
          tag="p">
          <template #link>
            <a :href="vendorDocumentationLink"
              target="_blank">
              {{ $t('general.documentation') }}
              <external-link-indicator />
            </a>
          </template>
        </i18n>
      </div>
    </template>
  </simple-confirmation-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  ARUBA_PRODUCT,
  CISCO_FTDV_PRODUCT,
  CISCO_c8000_PRODUCT,
  FORTINET_PRODUCT,
  PALO_ALTO_PRODUCT,
  PRISMA_3108_PRODUCT,
  PRISMA_7108_PRODUCT,
  SIX_WIND_PRODUCT,
  VERSA_PRODUCT,
  VMWARE_PRODUCT,
} from '@/Globals'
import { captureEvent } from '@/utils/analyticUtils'

import SimpleConfirmationDialog from '@/components/ui-components/SimpleConfirmationDialog.vue'
import ExternalLinkIndicator from '@/components/ui-components/ExternalLinkIndicator.vue'

export default {
  components: {
    'simple-confirmation-dialog': SimpleConfirmationDialog,
    'external-link-indicator': ExternalLinkIndicator,
  },

  data() {
    return {
      showTransitModal: false,
      newMveUid: '',
    }
  },

  computed: {
    ...mapGetters('Services', ['myPorts']),
    newMveReadyForTransitVxc() {
      return this.myPorts.find(port => port.productUid === this.newMveUid)
    },
    newMveReadyForTransitVxcVendor() {
      return this.newMveReadyForTransitVxc?.vendorConfig?._vendor || ''
    },
    vendorDocumentationLink() {
      const productCode = this.newMveReadyForTransitVxc?.vendorConfig?._productCode || ''

      let link = 'https://docs.megaport.com/mve/'

      switch (productCode) {
        case SIX_WIND_PRODUCT:
          link += '6wind/creating-mve/'
          break
        case ARUBA_PRODUCT:
          link += 'aruba/creating/'
          break
        case CISCO_c8000_PRODUCT:
          link += 'cisco/creating/creating-mve-autonomous/'
          break
        case CISCO_FTDV_PRODUCT:
          link += 'cisco/creating/creating-mve-firewall/'
          break
        case FORTINET_PRODUCT:
          link += 'fortinet/creating-mve/'
          break
        case PALO_ALTO_PRODUCT:
          link += 'palo-alto/creating-mve/'
          break
        case PRISMA_3108_PRODUCT:
        case PRISMA_7108_PRODUCT:
          link += 'palo-alto/creating-mve-prisma/'
          break
        case VERSA_PRODUCT:
          link += 'versa/creating-mve/'
          break
        case VMWARE_PRODUCT:
          link += 'vmware/creating-mve/'
          break
      }

      return link
    },
  },

  activated() {
    if (this.$route.query.new_mve) {
      this.newMveUid = this.$route.query.new_mve
      // So we get that nice opening animation
      this.$nextTick(() => { this.showTransitModal = true })
    }
  },

  methods: {
    handleTransitModalClose(event) {
      if (event === 'confirm') this.$router.push(`/create-connection/${this.newMveUid}?type=TRANSIT`)
      this.showTransitModal = false
      captureEvent(`services.add-transit.${event}.click`)
    },
  },
}
</script>

<style lang="scss" scoped>
.simple-confirmation-dialog-title-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  h1 {
    font-size: 2rem;
  }
}

.simple-confirmation-dialog-body > p {
  margin-top: 0;
}

::v-deep .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
