<template>
  <div v-if="conflicts.length"
    class="conflict-tag"
    @click.stop>
    <el-tag v-for="conflict in conflicts"
      :key="conflict.type"
      :type="conflict.variant || 'warning'"
      size="medium"
      class="hover-lighter">
      <mu-mega-icon v-if="conflict.outageIcon"
        icon="outages"
        width="18" />
      <i v-else
        class="fas"
        :class="conflict.spinner ? 'fa-spinner fa-spin' : 'fa-exclamation-triangle'"
        aria-hidden="true" />
      {{ conflict.text }}
      <el-button v-if="hasAction(conflict)"
        data-testid="conflict-action-button"
        class="ml-1"
        size="mini"
        @click.stop="doAction(conflict)">
        <span v-if="conflict.actionText">
          {{ conflict.actionText }}
        </span>
        <i v-else
          class="fas fa-arrow-right"
          aria-hidden="true" />
      </el-button>
    </el-tag>

    <un-cancel :visible.sync="showUncancel"
      :service-uid="service.productUid" />
  </div>
</template>

<script>
import { PUBLIC_COMPANY_ID } from '@/Globals.js'
import { mapState, mapGetters } from 'vuex'

import UnCancelComponent from '@/components/cancel/UnCancel.vue'
import { mpDate } from '@/helpers.js'

export default {
  name: 'OperationConflict',
  components: {
    'un-cancel': UnCancelComponent,
  },
  inject: ['disabledFeatures'],

  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showUncancel: false,
    }
  },
  computed: {
    ...mapState({ companyId: state => state.Company.data.companyId }),
    ...mapState(['showSidebar']),
    ...mapState('Services', ['locations']),
    ...mapGetters('Auth', ['hasAuth', 'isManagedAccount']),
    ...mapGetters('Markets', ['supplyRegions']),
    ...mapGetters('Company', ['companySupplyRegions']),
    ...mapGetters('Services', ['myPorts']),
    ...mapGetters('ServiceStatus', ['uidsWithOngoingMaintenance', 'uidsWithOngoingOutages']),
    canRestoreService() {
      // No matter what you cannot restore without the correct auth
      if (!this.hasAuth('place_order')) return false

      // If this port is part of a LAG...
      if (this.service.aggregationId) {
        // ...we can only restore if we are the LAG primary...
        if (this.service.lagPrimary) return true
        // ...or if the LAG primary isn't cancelled too.
        const lagPrimary = this.myPorts.find(port => port.lagPrimary && port.aggregationId === parseInt(this.service.aggregationId))
        return lagPrimary.provisioningStatus !== this.G_PROVISIONING_CANCELLED
      }
      // Otherwise we can restore
      return true
    },
    canEnableBillingMarkets() {
      return !this.isManagedAccount
        && this.hasAuth('financials')
    },
    conflicts() {
      const conflicts = []

      // Creating ENS Event saves short uid list via UI through payload hence need to validate via short uid
      // Will create a ticket to streamline this flow, in which will revisit here to make validation through productUid
      const subUid = this.service.productUid.split('-')[0]
      if (this.uidsWithOngoingMaintenance.includes(subUid)) {
        conflicts.push({
          outageIcon: true,
          type: 'has-ongoing-maintenance',
          text: this.$t('services.has-ongoing-maintenance'),
          linkTo: {
            path: 'service-status',
            query: {
              tab: 'maintenance',
              state: 'Running',
              searchText: subUid,
            },
          },
          actionText: this.$t('general.view'),
        })
      }
      if (this.uidsWithOngoingOutages.includes(subUid)) {
        conflicts.push({
          outageIcon: true,
          type: 'has-ongoing-outage',
          text: this.$t('services.has-ongoing-outage'),
          linkTo: {
            path: 'service-status',
            query: {
              tab: 'outages',
              state: 'Ongoing',
              searchText: subUid,
            },
          },
          actionText: this.$t('general.view'),
          variant: 'danger',
        })
      }

      if (this.service.provisioningStatus === this.G_PROVISIONING_LOADING) {
        conflicts.push({
          type: 'loading-state-info',
          text: this.$t('services.loading-state-info'),
          spinner: true,
        })
      }

      if (this.service.provisioningStatus === this.G_PROVISIONING_DESIGN_DEPLOY) {
        conflicts.push({
          type: 'waiting-on-deploy',
          text: this.$t('services.waiting-on-deploy'),
          spinner: true,
        })
      }

      if (this.companyId === PUBLIC_COMPANY_ID) {
        conflicts.push({
          type: 'no-company',
          text: this.$t('services.no-company'),
          linkTo: '/company/profile',
          actionText: this.$t('services.click-setup'),
        })
      }

      if (this.service.provisioningStatus === this.G_PROVISIONING_CANCELLED) {
        conflicts.push({
          type: 'cancelled',
          text: this.service.terminateDate
            ? this.$t('services.cancelled-when', { when: mpDate(this.service.terminateDate) }) : this.$t('services.cancel-unknown'),
          action: this.canRestoreService ? this.handleUncancelClick : null,
          actionText: this.canRestoreService ? this.$t('services.click-restore') : null,
        })
      }

      if (this.service.provisioningStatus === this.G_PROVISIONING_CANCELLED_PARENT) {
        conflicts.push({
          type: 'cancelled',
          text: this.service.terminateDate
            ? this.$t('services.cancelled-when', { when: mpDate(this.service.terminateDate) }) : this.$t('services.cancel-unknown'),
        })
      }

      if (this.service.vxcApproval) {
        if (this.service.vxcApproval.status === this.G_PROVISIONING_PENDING_INTERNAL) {
          const where = this.service.companyName ? this.service.companyName : this.$t('general.unknown')
          if (this.service.vxcApproval.type === 'SPEED_CHANGE') {
            conflicts.push({
              type: 'pending-internal-speed-approval',
              text: this.$t('services.pending-internal-speed-approval', { where }),
              linkTo: this.hasAuth('modify_service') ? `/edit-connection/${this.service.productUid}` : null,
              spinner: !this.hasAuth('modify_service'),
            })
          } else {
            conflicts.push({
              type: 'pending-internal-connection-approval',
              text: this.$t('services.pending-internal-connection-approval', { where }),
              linkTo: this.hasAuth('modify_service') ? `/edit-connection/${this.service.productUid}` : null,
              spinner: !this.hasAuth('modify_service'),
            })
          }
        }

        if (this.service.vxcApproval.status === this.G_PROVISIONING_PENDING_EXTERNAL) {
          const where = this.service.vxcApproval.message ? this.service.vxcApproval.message : this.$t('general.unknown')
          if (this.service.vxcApproval.type === 'SPEED_CHANGE') {
            conflicts.push({
              type: 'pending-3rd-party-speed-approval',
              text: this.$t('services.pending-3rd-party-speed-approval', { where }),
              spinner: true,
            })
          } else {
            conflicts.push({
              type: 'pending-external-connection-approval',
              text: this.$t('services.pending-external-connection-approval', { where }),
              spinner: true,
            })
          }
        }
      }

      if (this.service.provisioningStatus === this.G_PROVISIONING_DEPLOYABLE ||
          this.service.productType === this.G_PRODUCT_TYPE_VXC && this.service.provisioningStatus === this.G_PROVISIONING_CONFIGURED) {
        conflicts.push({
          type: 'waiting-for-resources',
          text: [this.G_PRODUCT_TYPE_MEGAPORT, this.G_PRODUCT_TYPE_MCR2].includes(this.service.productType)
            ? this.$t('ports.awaiting-resources', { type: this.service.virtual ? this.$t('productNames.mcr') : this.$t('productNames.port') })
            : this.$t('services.waiting-for-resources'),
          spinner: true,
        })
      }

      const location = this.locations.find(loc => this.service.locationId === loc.id)
      if (
        location &&
        location.market &&
        !location.marketEnabled &&
        !this.supplyRegions.includes(location.market) &&
        !this.companySupplyRegions.includes(location.market)
      ) {
        conflicts.push({
          type: 'missing-billing-market',
          text: this.$t('general.missing-billing-market', { country: location.country }),
          linkTo: this.canEnableBillingMarkets ? '/company/markets' : null,
          actionText: this.canEnableBillingMarkets ? this.$t('general.click-enable') : null,
        })
      }

      if (this.service.provisioningStatus === this.G_PROVISIONING_DESIGN) {
        if (this.disabledFeatures.configuredServices) {
          const serviceTypeUrlParam = this.service.productType === this.G_PRODUCT_TYPE_MEGAPORT ? 'port' : 'mcr'

          conflicts.push({
            type: 'in-design',
            text: this.$t('services.in-design-not-deployed'),
            linkTo: [this.G_PRODUCT_TYPE_MEGAPORT, this.G_PRODUCT_TYPE_MCR2].includes(this.service.productType)
              ? `/create-megaport/${serviceTypeUrlParam}/${this.service.productUid}`
              : `/edit-connection/${this.service.productUid}`,
          })
        } else {
          conflicts.push({
            type: 'in-design',
            text: this.$t('services.in-design-not-ordered'),
            action: !this.showSidebar
              ? this.openSidebar
              : null,
            actionText: !this.showSidebar
              ? this.$t('general.open-sidebar')
              : null,
          })
        }
      }

      return conflicts
    },
  },
  methods: {
    handleUncancelClick() {
      if (this.hasAuth('place_order')) {
        this.showUncancel = true
      }
    },
    openSidebar() {
      this.$root.$emit('showActionAside')
    },
    hasAction(conflict) {
      return conflict.linkTo || conflict.action
    },
    doAction(conflict) {
      if (conflict.linkTo) {
        this.$router.push(conflict.linkTo)
      } else if (conflict.action) {
        conflict.action()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.conflict-tag {
  padding-top: 0.4rem;
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .el-tag {
    white-space: initial;
    height: initial;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    line-height: 1.5;
    padding: 0.4rem 0.8rem;
    max-width: fit-content;
    & > i, & > svg {
      padding: 0.2rem;
      margin-right: 0.4rem;
    }
  }
  .el-button--mini {
    padding: 0.4rem 0.8rem;
  }
}
</style>
